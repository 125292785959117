import React, { useState, forwardRef, useImperativeHandle, useMemo, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import moment from 'moment-timezone';
import { Feather } from '@expo/vector-icons';
import { useToast } from 'react-native-toast-notifications';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _last from 'lodash/last';
import _filter from 'lodash/filter';
import _isNil from 'lodash/isNil';
import _findIndex from 'lodash/findIndex';
import _head from 'lodash/head';

import Text from '../../../../components/Text';
import DatePicker from '../../../../components/DatePicker';
import TimePicker from '../../../../components/TimePicker';
import IconLabelCard from '../../../../components/IconLabelCard';
import SeatSelector from '../../../../components/SeatSelector';
import { COLORS } from '../../../../constants/colors';
import {
  areAllBookingsValid,
  getDeskBookingCharges,
  getDeskBookingPayloadB2B,
  getDeskBookingPayloadB2C,
  getInitialDayBooking,
  getMinSeats,
  getNewBookingPlaceholder,
} from '../../../../utils/common';
import { MAX_ALLOWED_BOOKINGS, NUMBERS, SPACE_BOOKING_TYPES, STATUS } from '../../../../constants/common';
import { calculateBookingAnounts, getSeatsAvailable } from '../../../../api/common';
import AdminActions from './AdminActions';
import { getIsB2CUser } from '../../../../selectors/organization';
import BookingPrice from '../BookingPrice/BookingPrice';

const DeskBooking = forwardRef(({ isB2CUser, timeStamp, spaceDetails, user, organization, disableEdit = false, priceDiscount }, ref) => {
  const [dayBookings, setDayBookings] = useState([]);
  const toast = useToast();

  useEffect(() => {
    async function getInitialBooking() {
      const initialBooking = getInitialDayBooking(timeStamp, spaceDetails);
      updateSeatsAvailable(_get(initialBooking, 'id'), initialBooking);
      setDayBookings([initialBooking]);
    }
    getInitialBooking();
  }, []);

  const updateSeatsAvailable = async (id, bookingDetails) => {
    const seatsAvailable = await getSeatsAvailable({
      spaceId: _get(spaceDetails, "spaceId"),
      dates: [_get(bookingDetails, "bookingDate")],
      numberOfSeats: 1,
      duration: 1,
    })

    setDayBookings(bookings => {
      const newDayBookings = [...bookings];
      const currentBooking = _findIndex(newDayBookings, booking => _get(booking, 'id') === id);
      if (currentBooking !== -1) {
        newDayBookings[currentBooking] = { ...bookingDetails, seatsAvailable };
      }
      return newDayBookings;
    });
  };
  useImperativeHandle(
    ref,
    () => ({
      getBookingPayload() {
        const validBookings = areAllBookingsValid(spaceDetails, dayBookings, 'Day', organization);
        if (validBookings.status === STATUS.FAILED) {
          toast.show(validBookings.msg);
          return false;
        }



        return isB2C
          ? getDeskBookingPayloadB2C(spaceDetails, user, dayBookings)
          : getDeskBookingPayloadB2B(spaceDetails, user, dayBookings);
      },
      getPriceToCollect() {
        return price
      },
    }),
    [spaceDetails, user, dayBookings, organization]
  );

  const updateBookings = (index, key, value) => {
    const currentDayBookings = [...dayBookings];
    setDayBookings(_set(currentDayBookings, `${index}.${key}`, value));
  };

  const updateSeatsNumber = index => seats => {
    if (
      seats >= getMinSeats(spaceDetails) &&
      seats <= _get(dayBookings, `${index}.seatsAvailable`, 0)
    ) {
      // setNoOfSeats(seats)
      updateBookings(index, "noOfSeats", seats)
    }
  }

  const onSelectDateTime = index => async (mode, time) => {
    if (mode === 'date') {
      if (time > moment().startOf('day').valueOf()) {
        updateBookings(index, 'seatsAvailable', null);
        updateBookings(index, 'bookingDate', time);
        updateSeatsAvailable(_get(dayBookings[index], 'id'), dayBookings[index]);
      } else {
        toast.show('Please select a valid date.');
      }
    } else {
      updateBookings(index, 'startTime', time);
    }
  };

  const addBooking = async () => {
    const lastBooking = _last(dayBookings);
    const newPlaceHolder = getNewBookingPlaceholder(
      _get(lastBooking, 'bookingDate'),
      _get(lastBooking, 'startTime'),
      spaceDetails
    );
    setDayBookings([...dayBookings, newPlaceHolder]);
    updateSeatsAvailable(_get(newPlaceHolder, 'id'), newPlaceHolder);
  };

  const addOthersDetailsForBookings = async selectedUsersIds => {
    updateBookings(0, 'bookingForOthers', _size(selectedUsersIds) > 0);
    updateBookings(0, 'custIds', selectedUsersIds);
  };

  const removeBooking = index => () => {
    setDayBookings(_filter(dayBookings, (_, bookingIndex) => bookingIndex !== index));
  };

  const price = useMemo(
    () => getDeskBookingCharges(spaceDetails, dayBookings, SPACE_BOOKING_TYPES.DAY,priceDiscount),
    [spaceDetails, dayBookings, priceDiscount]
  );


  const isB2C = useMemo(() => isB2CUser);

  return (
    <View>
      {isB2C ? (
        <View >
          <BookingPrice price={price} />
        </View>
      ) : (
        <View style={[styles.baseMarginTop, styles.totalPrice]}>
          <Text size={3}>Credits needed</Text>
          <Text size={2} variant="bold">
            {`₹ ${_get(price, "totalBookingPrice")}`}
          </Text>
        </View>
      )}

      {_get(organization, 'config.dayPassEndTime') ? (
        <View>
          <Text size={4} variant="medium" numberOfLines={0} style={{ textAlign: 'left' }}>
            * As per your organization's policy, you can book and use this space up to {_get(organization, 'config.dayPassEndTime')} only.
          </Text>
        </View>
      ) : null}



      {_map(dayBookings, (booking, index) => {
        const { bookingDate, startTime, noOfSeats, seatsAvailable } = booking;
        return (
          <React.Fragment key={index}>
            {_size(dayBookings) > 1 ? (
              <View style={[styles.bookingHeading, styles.margin30]}>
                <Text variant="bold" size={3} color={COLORS.TEXT_COLOR_SECONDARY}>
                  {NUMBERS[index]} &nbsp; Booking
                </Text>
                <Text variant="bold" size={3} style={styles.cancelBooking} onPress={removeBooking(index)}>
                  Cancel
                </Text>
              </View>
            ) : null}
            <View style={[styles.dateTimeContainer, styles.margin30]}>
              <View style={styles.fullFlex}>
                <Text variant="bold" size={3} style={{ textAlign: 'left' }}>
                  Date
                </Text>
                <View style={styles.subMarginTop}>
                  <DatePicker
                    pickerMode="date"
                    onSelect={onSelectDateTime(index)}
                    date={new Date(Number(bookingDate))}
                    disableEdit={disableEdit}
                  />
                </View>
              </View>
              <View style={styles.fullFlex}>
                <Text variant="bold" size={3} style={{ textAlign: 'left' }}>
                  Next Slot
                </Text>
                <View style={styles.subMarginTop}>
                  <TimePicker
                    pickerMode="time"
                    onSelect={onSelectDateTime(index)}
                    date={new Date(Number(startTime))}
                    disableEdit={disableEdit}
                  />
                </View>
              </View>
            </View>

            <View style={[styles.margin30, styles.dateTimeContainer]}>
              <View style={styles.fullFlex}>
                <Text variant="bold" size={3} style={{ textAlign: 'left' }}>
                  Day Booking
                </Text>
                <View style={[styles.dayBookingSelection, styles.subMarginTop]}>
                  <Feather name="check" size={16} color={COLORS.WHITE} />
                </View>
              </View>
              <View style={styles.fullFlex}>
                <Text variant="bold" size={3} style={{ textAlign: 'left' }}>
                  Seats
                </Text>
                <View style={styles.subMarginTop}>
                  <SeatSelector isOrganizationAdmin={_get(user, 'isOrganizationAdmin')} config={organization.config} seats={noOfSeats} setSeats={updateSeatsNumber(index)} disableEdit={disableEdit} />
                </View>
                <Text variant="bold" size={3} style={[styles.subMarginTop, { textAlign: 'left' }]} color={COLORS.TEXT_COLOR_SECONDARY}>
                  {!_isNil(seatsAvailable)
                    ? `${seatsAvailable}/${_get(spaceDetails, 'seatsAvailable')} seats available`
                    : 'Loading...'}
                </Text>
              </View>
            </View>

            {(_get(user, 'isOrganizationAdmin')) && _size(dayBookings) === 1 ? (
              <AdminActions
                user={user}
                booking={booking}
                index={index}
                addOthersDetailsForBookings={addOthersDetailsForBookings}
              />
            ) : null}
          </React.Fragment>
        );
      })}

      {_size(dayBookings) < MAX_ALLOWED_BOOKINGS &&
        _size(dayBookings) > 0 &&
        !disableEdit &&
        (!_get(organization, 'config.disableMultiDayBookings') || _get(user, 'isOrganizationAdmin')) &&
        !_get(_head(dayBookings), 'bookingForOthers') ? (
        <Text
          size={3}
          variant="bold"
          color={COLORS.TEXT_COLOR_SECONDARY}
          style={styles.multipleDates}
          onPress={addBooking}>
          {_size(dayBookings) < 2 ? 'Book multiple dates' : 'Add more'}
        </Text>
      ) : null}
    </View>

  );
});


const styles = StyleSheet.create({
  margin30: {
    marginTop: 30,
  },
  marginB30: {
    marginBottom: 30,
  },
  dateTimeContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  fullFlex: {
    flex: 1,
  },
  baseMarginTop: {
    marginTop: 20,
  },
  subMarginTop: {
    marginTop: 10,
  },
  totalPrice: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    backgroundColor: COLORS.HALF_WHITE,
    borderRadius: 4,
  },
  dayBookingSelection: {
    borderRadius: 5,
    width: 38,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.PRIMARY_COLOR,
  },
  multipleDates: {
    textDecorationLine: 'underline',
    color: COLORS.TEXT_COLOR_SECONDARY,
    marginTop: 30,
    alignSelf: 'flex-start',
  },

  bookingHeading: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelBooking: {
    textDecorationLine: 'underline',
    color: COLORS.TEXT_COLOR_SECONDARY,
  },
});

export default DeskBooking;
