import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, OAuthProvider, browserPopupRedirectResolver } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getReactNativePersistence, initializeAuth } from 'firebase/auth/react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { getMessaging } from 'firebase/messaging';
import { getAnalytics, logEvent } from 'firebase/analytics';

let additionalConfigOptions = {};
if (Platform.OS === 'web')
  additionalConfigOptions = {
    popupRedirectResolver: browserPopupRedirectResolver,
  };

const environmments = {
  currentEnvironment: 'prod',
  prod: {
    apiKey: 'AIzaSyBCJXQI-JcKPAfG0ONO_x2D2yo_INuzV7c',
    authDomain: 'gofloaters.firebaseapp.com',
    databaseURL: 'https://gofloaters.firebaseio.com',
    projectId: 'gofloaters',
    storageBucket: 'gofloaters.appspot.com',
    messagingSenderId: '187167465849',
    appId: "1:187167465849:web:000c9c33b9be0b85910fa8",
  measurementId: "G-C407PQMLV9"
  },
  testing: {
    apiKey: 'AIzaSyDm7ZHIwzmDsxZqVxuVqo0AU9nufnfjt64',
    authDomain: 'gofloaters-in.firebaseapp.com',
    databaseURL: 'https://gofloaters-in-default-rtdb.asia-southeast1.firebasedatabase.app/',
    projectId: 'gofloaters-in',
    storageBucket: 'gofloaters-in.appspot.com',
    messagingSenderId: '945804464473',
    appId: '1:945804464473:web:2beeef3b60f10393587c48',
    measurementId: 'G-1FDB8QS1H0',
  },
  local: {
    apiKey: 'AIzaSyBCJXQI-JcKPAfG0ONO_x2D2yo_INuzV7c',
    authDomain: 'gofloaters.firebaseapp.com',
    databaseURL: 'https://gofloaters.firebaseio.com',
    projectId: 'gofloaters',
    storageBucket: 'gofloaters.appspot.com',
    messagingSenderId: '187167465849',
    appId: '1:187167465849:web:425043bfad6fcb46910fa8',
    measurementId: 'G-2KFVB0CQSV',
  },
};
const firebaseConfig = environmments[environmments.currentEnvironment];
// const firebaseConfig = {
//   apiKey: 'AIzaSyBCJXQI-JcKPAfG0ONO_x2D2yo_INuzV7c',
//   authDomain: 'gofloaters.firebaseapp.com',
//   databaseURL: 'https://gofloaters.firebaseio.com',
//   projectId: 'gofloaters',
//   storageBucket: 'gofloaters.appspot.com',
//   messagingSenderId: '187167465849',
//   appId: '1:187167465849:web:425043bfad6fcb46910fa8',
//   measurementId: 'G-2KFVB0CQSV',
// };
export const app = initializeApp(firebaseConfig);
export const auth = initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage),
  ...additionalConfigOptions,
});
export const googleProvider = new GoogleAuthProvider();
export const appleProvider = new OAuthProvider('apple.com');
export const database = getDatabase(app);
export const analytics = getAnalytics(app);
logEvent(analytics, 'test_event', { param: 'value' });
console.log('Firebase Analytics initialized');
export const currentEnvironment = environmments.currentEnvironment;
